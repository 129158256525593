import { Component, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public data: any = {
    fbf : {
      brandfallSteuerung : false,
      akustikAus : false,
      ueAb : false,
      uePruefen : false
    },
    fat : {
      ebene : 0,
      summerAus : false
    },
    bmz : {
      akustikAus : false,
      ueAb : false,
      brandfallSteuerung : false
    },

    status : {
      ueAb : false,
      akustik : false,
      alarm : false,
      lastAlarm : null,
      brandfallSteuerung : false,
      loeschanlage : false,
      fsd : 0
    }
  };

  public stoerungen: any[] = [
    {
      linie : '   01/03',
      name : 'FSD',
      zeile : 'Nicht verriegelt',
      aktiv : false
    }
  ];

  public schleifen: any[] = [
    {
      linie : '   01/02',
      name : 'FSE',
      zeile : 'Freischaltelement',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   15/01',
      name : 'Auto Melder',
      zeile : 'Küche EG',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   15/02',
      name : 'Auto Melder',
      zeile : 'Speisesaal',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   15/03',
      name : 'Handmelder',
      zeile : 'Haupteingang Ost',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   21/01',
      name : 'Auto Melder',
      zeile : 'Zimmer 1',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   21/02',
      name : 'Handmelder',
      zeile : 'Flur Ost',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '   21/03',
      name : 'Handmelder',
      zeile : 'Flur West',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '    1/01',
      name : 'Übertragung',
      zeile : 'ÜE abgeschaltet',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '    1/05',
      name : 'Akustik',
      zeile : 'Akustik abgeschaltet',
      aktiv : false,
      abgeschaltet : false
    },
    {
      linie : '    1/06',
      name : 'Brandfall',
      zeile : 'Brandfallsteuerung ab',
      aktiv : false,
      abgeschaltet : false
    }
  ];

  public fatMeldungPosition = 0;

  public aktiveAlarme: any[] = [];
  public aktiveStoerungen: any[] = [];
  public aktiveAbschaltungen: any[] = [];

  private audioSirene = new Audio();
  private audioFAT = new Audio();

  constructor() {
    this.audioSirene.src = 'assets/sirene.mp3';
    this.audioSirene.load();
    this.audioSirene.loop = true;

    this.audioFAT.src = 'assets/fat_alarm.mp3';
    this.audioFAT.load();
    this.audioFAT.loop = true;
  }

  public clickUeAb() {
    this.data.fbf.ueAb = !this.data.fbf.ueAb;
    this.data.status.ueAb = this.data.fbf.ueAb || this.data.bmz.ueAb;
    this.abschaltung(7, this.data.status.ueAb);
  }

  public bmzUe() {
    this.data.bmz.ueAb = !this.data.bmz.ueAb;
    this.data.status.ueAb = this.data.fbf.ueAb || this.data.bmz.ueAb;
    this.abschaltung(7, this.data.status.ueAb);
  }

  public fbfAkustikAus() {
    this.data.fbf.akustikAus = !this.data.fbf.akustikAus;
    this.data.status.akustik = this.data.fbf.akustikAus || this.data.bmz.akustikAus;
    this.abschaltung(8, this.data.status.akustik);
    this.checkSirene();
  }

  public bmzAkustik() {
    this.data.bmz.akustikAus = !this.data.bmz.akustikAus;
    this.data.status.akustik = this.data.fbf.akustikAus || this.data.bmz.akustikAus;
    this.abschaltung(8, this.data.status.akustik);
    this.checkSirene();
  }

  
  public bmzBrandfallsteuerung() {
    this.data.bmz.brandfallSteuerung = !this.data.bmz.brandfallSteuerung;
    this.data.status.brandfallSteuerung = this.data.bmz.brandfallSteuerung || this.data.fbf.brandfallSteuerung;
    this.abschaltung(9, this.data.status.brandfallSteuerung);
    this.checkSirene();
  }

  public clickBrandfallsteuerungAb() {
    this.data.fbf.brandfallSteuerung = !this.data.fbf.brandfallSteuerung;
    this.data.status.brandfallSteuerung = this.data.bmz.brandfallSteuerung || this.data.fbf.brandfallSteuerung;
    this.abschaltung(9, this.data.status.brandfallSteuerung);
    this.checkSirene();
  }

  public uePruefen() {
    this.data.fbf.uePruefen = !this.data.fbf.uePruefen;
  }

  public fatSummer() {
    this.data.fat.summerAus = !this.data.fat.summerAus;
    this.checkSirene();
  }

  public bmzReset(anFbf: boolean) {
    /* Prüfen, ob alles in Ruhe ist! */
    for (let i = 0, len = this.schleifen.length; i < len; i++) {
      if (this.schleifen[i].aktiv && !this.schleifen[i].abgeschaltet) {
       return;
      }
    }

    this.data.status.alarm = false;
    
    this.aktiveAlarme = [];

    if(anFbf) {
      this.data.status.lastAlarm = null;      
    }

    this.checkSirene();
  }
  
  public fsdKlappe() {
    if(this.isUeAusgeloest) {
      this.data.status.fsd = 1;
      this.stoerung(0, true);
    } else {
      alert('Die Klappe ist verriegelt! Die Übertragungseinrichtung muss augelöst haben!');
    }
  }

  public fsdOeffnen() {
    this.data.status.fsd = 2;
  }

  public fsdEntnehmen() {
    this.data.status.fsd = 3;
  }

  public fsdReset() {
    this.data.status.fsd = 0;
    this.stoerung(0, false);
  }

  public abschaltung(index, aktiv?: boolean) {
    const selStoerung = this.schleifen[index];    

    if(aktiv == null) {
      aktiv = !selStoerung.abgeschaltet;
    }

    selStoerung.abgeschaltet = aktiv;

    if(aktiv) {
      for (let i = 0, len = this.aktiveAbschaltungen.length; i < len; i++) {
        if (this.aktiveAbschaltungen[i].index === index) {
        return;
        }
      }
    
      this.aktiveAbschaltungen.push( {
        index,
        nummer : selStoerung.linie,
        typ : selStoerung.name,
        ort : selStoerung.zeile,
        zeit : Date()
      });
    } else {

      if(selStoerung.aktiv && !selStoerung.abgeschaltet)
      {
        this.alarm(index, selStoerung.linie, selStoerung.name, selStoerung.zeile);
      }



      for (let i = 0, len = this.aktiveAbschaltungen.length; i < len; i++) {
        if (this.aktiveAbschaltungen[i].index === index) {
          this.aktiveAbschaltungen.splice(i, 1);
          if(this.aktiveAbschaltungen.length === 0) {
            this.data.fat.ebene = 0;
          }
          return;
        }
      }
    }
  }

  private stoerung(index, aktiv: boolean) {
    const selStoerung = this.stoerungen[index];    

    if(aktiv) {
      for (let i = 0, len = this.aktiveStoerungen.length; i < len; i++) {
        if (this.aktiveStoerungen[i].index === index) {
        return;
        }
      }
    
      this.aktiveStoerungen.push( {
        index,
        nummer : selStoerung.linie,
        typ : selStoerung.name,
        ort : selStoerung.zeile,
        zeit : Date()
      });
    } else {
      for (let i = 0, len = this.aktiveStoerungen.length; i < len; i++) {
        if (this.aktiveStoerungen[i].index === index) {
          this.aktiveStoerungen.splice(i, 1);
          if(this.aktiveStoerungen.length === 0) {
            this.data.fat.ebene = 0;
          }
          return;
        }
      }
    }
  }

  public alarmToggle(index: number) {
    const alarmSchleife = this.schleifen[index];    
    const value = !alarmSchleife.aktiv;

    if(value === true && index == 0) {
      /* FSE geht nur auszulösen, wenn ÜE nicht abgeschaltet ist */
      if(this.data.status.ueAb) {
        return;
      }
    }

    if(value && !alarmSchleife.aktiv && !alarmSchleife.abgeschaltet)
    {
      this.alarm(index, alarmSchleife.linie, alarmSchleife.name, alarmSchleife.zeile);
    }

    alarmSchleife.aktiv = value;
    
  }

  private alarm(index, nummer, typ, ort) {

    for (let i = 0, len = this.aktiveAlarme.length; i < len; i++) {
      if (this.aktiveAlarme[i].index === index) {
       return;
      }
    }

    this.aktiveAlarme.push( {
      index,
      nummer,
      typ,
      ort,
      zeit : Date()
    });



    this.data.status.alarm = true;
    this.data.status.lastAlarm = Date();
    this.data.fat.ebene = 0;
    this.checkSirene();
  }

  private checkSirene() {
    const result =  this.data.status.alarm && !this.data.status.akustik;

    if (result) {
      this.audioSirene.play();
    } else {
      this.audioSirene.pause();
    }

    const resultFat =  this.data.status.alarm && !this.data.fat.summerAus;

    if (resultFat) {
      this.audioFAT.play();
    } else {
      this.audioFAT.pause();
    }

    if (this.data.status.alarm && !this.isBrandfallsteuerungAb) {
      this.data.status.loeschanlage = true;
    } else if(!this.data.status.alarm) {
      this.data.status.loeschanlage = false;
    }
  }

  public wechselFatEbene() {
    if (this.data.fat.ebene === 0) {
      if (this.aktiveStoerungen.length > 0) {
        this.data.fat.ebene = 1;
      } else if (this.aktiveAbschaltungen.length > 0) {
        this.data.fat.ebene = 2;
      } else {
        this.data.fat.ebene = 0;
      }
    } else if (this.data.fat.ebene === 1) {
      if (this.aktiveAbschaltungen.length > 0) {
        this.data.fat.ebene = 2;
      } else {
        this.data.fat.ebene = 0;
      }
    } else {
      this.data.fat.ebene = 0;
    }
    this.fatMeldungPosition = 0;
  }

  get isUeAusgeloest(): boolean {
    return (this.data.status.alarm || this.data.fbf.uePruefen) && !this.data.fbf.ueAb;
  }

  get isAlarm(): boolean {
    return this.data.status.alarm;
  }

  get isAlarmFBF(): boolean {
    var schwelle = new Date();
    schwelle.setMinutes(schwelle.getMinutes() - 15);
    const vergleich = new Date(this.data.status.lastAlarm);
    return this.isAlarm || (this.data.status.lastAlarm != null && vergleich > schwelle);
  }

  get isBrandfallsteuerungAb(): boolean {
    return this.data.status.brandfallSteuerung;
  }

  get fatEbeneAlarmAktiv(): boolean {
    return this.data.fat.ebene === 0 && this.aktiveAlarme.length > 0;
  }

  get fatEbeneAlarmBlink(): boolean {
    const res = this.data.fat.ebene !== 0 && this.aktiveAlarme.length > 0;
    console.log(res);
    return res;
  }

  get fatEbeneStoerungAktiv(): boolean {
    return this.data.fat.ebene === 1 && this.aktiveStoerungen.length > 0;
  }

  get fatEbeneStoerungBlink(): boolean {
    return this.data.fat.ebene !== 1 && this.aktiveStoerungen.length > 0;
  }

  get fatEbeneAbschaltungAktiv(): boolean {
    return this.data.fat.ebene === 2 && this.aktiveAbschaltungen.length > 0;
  }

  get fatEbeneAbschaltungBlink(): boolean {
    return this.data.fat.ebene !== 2 && this.aktiveAbschaltungen.length > 0;
  }

  get fatLetzteMeldung() {
    if (this.fatAktiveListe.length > 1) {
      const meldung = this.fatAktiveListe[this.fatAktiveListe.length - 1];
      return this.getMeldung(meldung);
    } else {
      return { zeile1 : '', zeile2 : '' };
    }
  }

  private getMeldung(data) {
    return { zeile1 : data.nummer + ' ' + data.typ, zeile2 : data.ort };
  }

  public fatMoveToPrev() {
    if (this.fatMeldungPosition > 0) {
      this.fatMeldungPosition--;
    }
  }

  public fatMoveToNext() {
    if (this.fatMeldungPosition < this.fatAktiveListe.length - 2) {
      this.fatMeldungPosition++;
    }
  }

  get fatHasPrevMeldung() {
    if (this.fatAktiveListe.length > 2 && this.fatMeldungPosition  > 0) {
      return true;
    } else {
      return false;
    }
  }

  get fatHasNextMeldung() {
    if (this.fatAktiveListe.length > 2 && this.fatMeldungPosition < this.fatAktiveListe.length - 2) {
      return true;
    } else {
      return false;
    }
  }

  get fatAktiveListe() {
    if (this.data.fat.ebene === 0) {
      return this.aktiveAlarme;
    } else if (this.data.fat.ebene === 1) {
      return this.aktiveStoerungen;
    } else {
      return this.aktiveAbschaltungen;
    }
  }

  get fatAktiveMeldung() {
    if (this.fatAktiveListe.length === 0) {
      return { zeile1 : '   FAT - Version 1.0', zeile2 : '  (c) Holger Martiker' };
    } else {
      const meldung = this.fatAktiveListe[this.fatMeldungPosition];
      return this.getMeldung(meldung);
    }
  }
}
