import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fat-text',
  templateUrl: './fat-text.component.html',
  styleUrls: ['./fat-text.component.css']
})
export class FatTextComponent implements OnInit {
  @Input() data : any = { zeile1 : '', zeile2 : ''};

  constructor() { }

  get zeile1() {
    return this.data.zeile1.split(" ").join("&nbsp;");
  }

  get zeile2() {
    return this.data.zeile2.split(" ").join("&nbsp;");
  }

  ngOnInit() {
  }

}
