import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-led',
  templateUrl: './led.component.html',
  styleUrls: ['./led.component.css']
})
export class LedComponent implements OnInit {
  @Input() color = 'green';
  @Input() active = false;
  @Input() blink = false;

  constructor() { }

  ngOnInit() {
  }

}
