import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-melder',
  templateUrl: './melder.component.html',
  styleUrls: ['./melder.component.css']
})
export class MelderComponent implements OnInit {

  @Input() label = '';
  
  constructor() { }

  ngOnInit() {
  }

}
